import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import Layout from '@/components/layout';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Lframe from '@/components/Lframe/Lframe';
import { Metadata } from '@latitude/metadata';
import { Box, Flex } from '@/components/Box/Box';
import Section from '@latitude/section';
import { Heading4 } from '@latitude/heading';
import { Text } from '@latitude/text';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Link } from '@latitude/link';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import { FeatureTiles } from '@latitude/feature-tiles';
import {
  AppStoreBadge,
  AppStoreBadgeContainer
} from '@/components/AppStoreBadge/AppStoreBadge';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  PADDING,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import heroImage from '../images/hero/mobile-app.webp';
import imgIPhoneAppScreens from '../images/iphone-app-screens.webp';
import PageData from '../data/pages/mobile-app.json';

const MobileApp = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer">
      <Metadata
        title="Mobile App | Gem by Latitude"
        description="Latitude app is a great tool to manage your Gem credit card or personal loan. Get started now."
        canonical="/mobile-app/"
      />

      <Lframe cardLowRate />

      <HeroBranded
        fastApply
        className="hero-control"
        css={`
          background: ${COLOR.GREY6};
          @media (max-width: ${BREAKPOINT.MD}) {
            .HeroContent {
              background-color: ${COLOR.WHITE};
            }
          }
        `}
        imageContent={
          <div
            css={`
              height: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              @media (min-width: ${BREAKPOINT.LG}) {
                justify-content: flex-end;
              }
            `}
          >
            <img
              src={heroImage}
              alt=""
              css={`
                height: 250px;
                width: auto;
                @media (min-width: ${BREAKPOINT.MD}) {
                  height: 300px;
                }
                @media (min-width: ${BREAKPOINT.LG}) {
                  height: 335px;
                }
              `}
            />
          </div>
        }
        title={<>Manage your money with the Latitude App</>}
        text={
          <div
            css={`
              padding: 8px 0 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 24px 0 0;
              }
            `}
          >
            <AnalyticsLocationProvider location="hero">
              <AppStoreBadgeContainer>
                <AppStoreBadge url="https://apps.apple.com/app/apple-store/id1373059866" />
                <AppStoreBadge url="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp" />
              </AppStoreBadgeContainer>
            </AnalyticsLocationProvider>
          </div>
        }
      />

      <div
        className="d-none d-lg-block"
        css="position: relative; z-index: 11;
        .sticky-navigation--fixed {
          position: fixed;
          }"
      >
        <StickyNavigation
          items={PageData.nav}
          offsetElem="[data-sticky-navigation-offset]"
        />
      </div>
      <FeatureTiles
        isCompact
        id="features"
        heading={PageData.content.whyChooseLatitude.title}
        tiles={PageData.content.whyChooseLatitude.tiles}
      />
      <HorizontalRule />
      <CardsSection />
      <GetStartedSection />
      <BrandedCallout
        line1={
          <React.Fragment>
            For FAQs, useful information and quick and easy forms
          </React.Fragment>
        }
        cta={
          <Box paddingTop={PADDING.P24} width="210px">
            <Link
              button={BUTTON_STYLE.SECONDARY_INVERSE}
              href="https://gemfinance.zendesk.com/hc/en-nz/categories/360003424158-Latitude-Mobile-App"
              trackId="callout-help-btn"
              target="_blank"
              rel="noopener noreferrer"
              trackEventData={{
                action: 'internal-link'
              }}
            >
              Visit our Help Centre
            </Link>
          </Box>
        }
      />
    </main>
  </Layout>
);

const CardsSection = () => (
  <Section
    id="cards"
    align={ALIGN.LEFT}
    css={`
      padding: 72px 0 !important;
      background-color: ${COLOR.GREY6};
    `}
  >
    <Box
      backgroundColor={COLOR.GREY6}
      css={`
        max-width: 730px;
        margin: 0 auto;
        padding: 0 16px;
        text-align: center;
      `}
    >
      <Heading4
        align={ALIGN.CENTER}
        color={COLOR.BLACK}
        css={`
          margin: 0 0 24px;
        `}
      >
        Take care of all your Latitude products in one easy to use app
      </Heading4>
      <Text align={ALIGN.CENTER}>
        Our easy to use app lets you take charge of your money, where you can
        view all your accounts, transactions, payments and timelines in one go.
      </Text>

      <Box
        css={`
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 56px 0;
          img {
            max-width: 96%;
            @media (min-width: ${BREAKPOINT.LG}) {
              width: 779px;
              max-width: none;
            }
          }
        `}
      >
        <img src={imgIPhoneAppScreens} alt="iPhone application screenshots" />
      </Box>
    </Box>
  </Section>
);

const GetStartedSection = () => {
  const heading = 'Get started now';
  return (
    <Section
      id="get-started"
      align={ALIGN.LEFT}
      css={`
        background-color: ${COLOR.BLUE_BABY};
      `}
    >
      <ConfettiFramedContainer
        framedContainerTheme={{
          contentBorderColor: 'none',
          frameColor: '#D0E9FF'
        }}
        hasPlainBgColor="#E8F4FF"
        heading={heading}
        innerContainerMaxWidth="730px"
        minHeight="280px"
      >
        <Box
          css={`
            padding: 40px 32px;
          `}
        >
          <ListNumbered
            counterBgColor="#0046AA"
            data={[
              'Download the Latitude App from the App Store or Google Play',
              'Create your Latitude ID',
              'Link your cards and manage your finances at the touch of a button!'
            ]}
            separator="none"
          />
          <Flex
            css={`
              margin: 32px auto 0;
              justify-content: center;
            `}
          >
            <AnalyticsLocationProvider location={heading}>
              <AppStoreBadgeContainer>
                <AppStoreBadge url="https://apps.apple.com/app/apple-store/id1373059866" />
                <AppStoreBadge url="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp" />
              </AppStoreBadgeContainer>
            </AnalyticsLocationProvider>
          </Flex>
        </Box>
      </ConfettiFramedContainer>
    </Section>
  );
};

export default MobileApp;
